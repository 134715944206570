<template>
  <div class="home">
    <TheLogin class="log-in"/>
  </div>
</template>

<script>
import TheLogin from '@/components/TheLogin'

export default {
  name: 'home',
  components: {
    TheLogin,
  }
}
</script>

<style lang="css">
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../assets/calendar_image.jpg');
    background-size: cover;
  }
  .log-in {
    margin-bottom: 100px;
  }
</style>
