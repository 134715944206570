<template>
  <div>
    <v-container grid-list-xs>
      <v-card
        class="mx-auto pb-5"
        id="card"
        elevation="10"
        max-width="344"
        outlined
      >
        <v-card-title>
          <v-row>
            <v-col cols="12" class="my-0 mb-n4 d-flex justify-end align-center">
              <div>
                <v-img
                  class="logo-img"
                  width="80"
                  src="@/assets/AgentOS-Logo.png"
                ></v-img>
              </div>
            </v-col>
            <v-col class="py-0 mt-n3 d-flex justify-center" cols="12">
              <div class="logo-wrapper">
                <v-img
                  class="mt-5"
                  max-height="100"
                  src="@/assets/app_logo_alpha_x2.png"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form @submit.stop>
            <v-container fluid>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    append-icon="mdi-account"
                    outlined
                    light
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    value=""
                    class="input-group--focused"
                    @click:append="show = !show"
                    @keyup.enter="login"
                    outlined
                    light
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n6">
          <v-row>
            <v-col class="px-0" cols="10" offset="1">
              <v-btn
                height="45"
                :loading="isLoading"
                @click="login"
                block
                color="#eb5d37"
                class="white--text"
                >Log in</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>

        <div v-if="loginError" class="px-7 error-msg">
          <span class="caption font-weight-regular red--text"
            >Invalid username or password; please make sure you have entered
            your details correctly. If you still can't log in, a Client
            Administrator on your system can reset your password Alternatively,
            please contact
            <a class="white--text" href="mailto:support@agentos.com"
              >support@agentos.com</a
            ></span
          >
          <v-divider class="my-2"></v-divider>
          <div class="caption text-center">
            <a class="" href="mailto:support@agentos.com"
              >support@agentos.com</a
            >
            <p>Call 029 2036 7960</p>
          </div>
          <div class="caption text-center font-weight-light">
            Opening times: Mon - Fri: 9am - 5pm
            <p>Sat/Sun: closed</p>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loginIfAgentOSSessionID();
  },
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      show: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      loginError: false,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        // .then( () => {
        //   this.$store.dispatch('sessionStatus')
        // })
        // .then( () => {
        //   this.$store.dispatch('fetchAppointments')
        //   console.log('appointments fetched successfully')
        // })
        .then(() => {
          this.$router.push("/negapp");
          // console.log('Log in successful')
          this.isLoading = false;
          this.loginError = false;
        })
        .catch((err) => {
          console.log("Login error: " + err);
          this.password = "";
          this.isLoading = false;
          this.loginError = true;
        });
    }, //login if we are passed a sessionID from AgentOS. If not, do nothing.
    loginIfAgentOSSessionID() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has("shortname") && urlParams.has("sessionid")) {
        this.isLoading = true;
        this.$store
          .dispatch("loginAgentOSSessionID", {
            shortName: urlParams.get("shortname"),
            sessionID: urlParams.get("sessionid"),
          })
          .then(() => {
            this.$router.push("/negapp");
            this.isLoading = false;
          })
          .catch((err) => {
            console.log("Login error: " + err);
            this.password = "";
            this.isLoading = false;
            this.loginError = true;
          });
      }
    },
  },
};
</script>

<style scoped>
/* @import url(https://fonts.googleapis.com/css?family=Roboto:300); */

#card {
  border: 2px solid primary;
  /* opacity: 0.975; */
  background: #fff;
  border-radius: 10px;
}

.logo-wrapper {
  max-width: 300px;
}

/* .company-name {
  margin: 25px auto 0px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
} */
/* #company-name-1 {
    font-family: 'Gugi', cursive;
    color: #eb5c37;
    font-size: 3.5rem;
  }
  #company-name-2 {
    font-family: 'Gugi', cursive;
    font-size: 1.6rem;
    border-radius: 18%;
    padding: 6px 5px;
    color: #343351;
  } */
</style>